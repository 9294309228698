<template>
    <section class="d-flex flex-column px-3">
        <div>
            <titulo-divisor titulo="Listas de chequeo">
                <el-popover ref="createServiceTypes" trigger="click" width="300">
                    <p class="text-center text-general f-600 m-3">Crear tipo de servicio</p>
                    <hr class="my-0" />
                    <div class="row mx-0 justify-content-center ">
                        <div class="col-10 my-3">
                            <p class="input-label-top">Tipo de servicio</p>
                            <el-input v-model="model.service_type" size="small" class="w-100" />
                        </div>
                    </div>
                    <hr class="my-0" />
                    <div class="text-right p-2">
                        <button class="btn btn-cerrar f-12" @click="$refs.createServiceTypes.showPopper = false">Cerrar</button>
                        <button class="btn btn-crear f-12 ml-2" @click="addService">Crear</button>
                    </div>
                    <el-tooltip slot="reference" content="Crear tipo de servicio" effect="light" visible-arrow>
                        <i :class="`icon-plus-circle text-general f-30 cr-pointer`" />
                    </el-tooltip>
                </el-popover>
            </titulo-divisor>
        </div>
        <div>
            <draggable tag="div" :list="services" class="" handle=".handle" @change="orderPositionServices($event)">
                <div v-for="(serviceType, index) in services" :key="index" class="row mx-0 border-bottom py-1">
                    <div class="d-flex my-auto">
                        <div class="d-flex justify-content-between pl-2" style="min-width: 200px;">
                            <div class="d-middle">
                                <i class="icon-menu handle pl-3 pr-2" />
                                <p class="f-12"> {{ serviceType.nombre }} </p>
                            </div>
                            <div class="btn__size">
                                <button class="btn-general br-5 w-100 h-100 f-12">{{serviceType.cantidadServicios}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto ml-auto">
                        <div class="d-flex icon-option">
                            <el-popover :ref="`editServiceTypes-${index}`" trigger="click" width="300">
                                <p class="text-center text-general f-600 m-3">Editar tipo de servicio</p>
                                <hr class="my-0" />
                                <div class="row mx-0 justify-content-center my-2">
                                    <div class="col-10 my-2">
                                        <p class="input-label-top">Tipo de servicio</p>
                                        <el-input v-model="editType" size="small" class="w-100" />
                                    </div>
                                </div>
                                <hr class="my-0" />
                                <div class="text-right p-2">
                                    <button class="btn btn-cerrar f-12" @click="$refs[`editServiceTypes-${index}`][0].showPopper = false">Cerrar</button>
                                    <button class="btn btn-crear f-12 ml-2" @click="editService(serviceType, index)">Guardar</button>
                                </div>
                                <div slot="reference">
                                    <el-tooltip  content="Editar" effect="light" visible-arrow>
                                        <i :class="`icon-pencil f-20 cr-pointer`"  @click="initialType(serviceType)" />
                                    </el-tooltip>
                                </div>
                            </el-popover>
                            <el-tooltip content="Eliminar" effect="light" visible-arrow>
                                <i class="icon-trash-can-outline f-20 cr-pointer" @click="beforeDeleteServiceType(serviceType)" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
        <modal-eliminar
        ref="modalDeleteServiceType"
        title="Eliminar tipo de servicio"
        mensaje="¿Está seguro que quiere eliminar este tipo de servicio?"
        @delete="deleteServiceType"
        />
    </section>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
    data(){
        return {
            toDelete: null,
            model:{
                service_type: ''
            },
            data: [],
            editType: ''
        }
    },
    computed: {
        ...mapGetters({
            services: 'configurar/talleres/tipo_servicio/services',
        }),
    },
    async created(){
        await this.listarServicios();
    },
    methods:{
        ...mapActions({
            Action_get_services: 'configurar/talleres/tipo_servicio/Action_get_services',
            Action_create_service: 'configurar/talleres/tipo_servicio/Action_create_service',
            Action_update_service: 'configurar/talleres/tipo_servicio/Action_update_service',
            Action_delete_services: 'configurar/talleres/tipo_servicio/Action_delete_services',
            Action_update_position_services: 'configurar/talleres/tipo_servicio/Action_update_position_services',
        }),
        initialType(item){
            this.editType = item.nombre
        },
        async listarServicios(){
            await this.Action_get_services();
        },
        beforeDeleteServiceType(serviceType){
            this.toDelete = serviceType?.id
            this.$refs.modalDeleteServiceType.toggle();
        },
        async deleteServiceType(){
            await this.Action_delete_services(this.toDelete);
        },
        handleNodeClick(e){
            console.log({clickNode: e});
        },
        async addService(){
            await this.Action_create_service({ nombre: this.model.service_type })
            this.$refs.createServiceTypes.showPopper = false
            this.model.service_type = ''
        },
        async editService(data, index){
            await this.Action_update_service({ nombre:this.editType, id:data.id })
            this.$refs[`editServiceTypes-${index}`][0].showPopper = false
            this.listarServicios()
        },
        async orderPositionServices(){
            const payload = this.services.map((e, idx) => {
                return {id:e.id, posicion:idx+1}
            })
            await this.Action_update_position_services({servicios: payload})
        }
    }
}
</script>

<style scoped>
.btn__size{
    width: 30px; 
    height: 30px;
}
</style>

<style>
.el-popover{
    padding: 0;
}
</style>